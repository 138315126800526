import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../../components/InnerPage';

const FortschreitendeOffenbarungZitate = () => (
    <InnerPage title="Zitate – Fortschreitende Offenbarung" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Fortschreitende Offenbarung"
            breadcrumbs={{
                greatgrandparent: 'Woran Bahá’í glauben',
                grandparent: 'Gott und der Mensch',
                parent: 'Fortschreitende Offenbarung',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Botschaften aus Akka">
                    Das Ziel der Religion, wie sie vom Himmel des heiligen
                    Willens Gottes offenbart ist, besteht darin, Einheit und
                    Eintracht unter den Völkern der Welt zu stiften; macht sie
                    nicht zur Ursache für Zwist und Streit. Die Religion Gottes
                    und Sein göttliches Gesetz sind die machtvollsten Werkzeuge
                    und die sichersten Mittel dafür, daß das Licht der Einheit
                    zwischen den Menschen anbricht. Der Fortschritt der Welt,
                    die Entwicklung der Nationen, die Ruhe der Völker und der
                    Frieden aller Erdenbewohner gehören zu den Grundsätzen und
                    Geboten Gottes…Wir rufen die Offenbarungen der Allmacht
                    Gottes – die Herrscher und Regenten auf Erden – auf, sich zu
                    regen und alles, was in ihrer Kraft steht, zu tun, um die
                    Zwietracht zu bannen und die Welt mit dem Licht der Einheit
                    zu erleuchten.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Botschaften aus Akka">
                    Der Hauptzweck, der den Glauben Gottes und Seine Religion
                    beseelt, ist, das Wohl des Menschengeschlechts zu sichern,
                    seine Einheit zu fördern und den Geist der Liebe und
                    Verbundenheit unter den Menschen zu pflegen. Laßt sie nicht
                    zur Quelle der Uneinigkeit und der Zwietracht, des Hasses
                    und der Feindschaft werden. Dies ist der gerade Pfad, die
                    feste, unverrückbare Grundlage. Was immer auf dieser
                    Grundlage errichtet ist, dessen Stärke können Wandel und
                    Wechsel der Welt nie beeinträchtigen, noch wird der Ablauf
                    zahlloser Jahrhunderte seinen Bau untergraben. Unsere
                    Hoffnung ist, daß sich die religiösen Führer der Welt und
                    ihre Herrscher vereint für die Neugestaltung dieses
                    Zeitalters und die Wiederherstellung seiner Wohlfahrt
                    erheben werden. Laßt sie, nachdem sie über seine Nöte
                    nachgedacht haben, zusammen beraten und nach sorgsamer,
                    reiflicher Überlegung einer kranken, schwer leidenden Welt
                    das Heilmittel darreichen, dessen sie bedarf.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Beantwortete Fragen">
                    In Wirklichkeit aber ist die Religion Gottes Verbreitung der
                    Wahrheit, Begründung von Wissen und Erkenntnis, und voller
                    Aufgeschlossenheit für Gelehrte; sie kultiviert die
                    Menschheit, entdeckt die Geheimnisse der Natur und
                    erleuchtet die Horizonte der Welt. Wie kann man also sagen,
                    daß sie der Erkenntnis entgegengesetzt ist? Gott behüte!
                    Nein, bei Gott ist Erkenntnis die herrlichste Gabe des
                    Menschen und die vornehmste der menschlichen
                    Vollkommenheiten. Erkenntnis zu bekämpfen ist Torheit, und
                    wer Erkenntnis und Wissenschaft haßt, ist kein Mensch,
                    sondern ein Tier ohne Verstand. Denn Erkenntnis ist Licht
                    und Leben, Glückseligkeit, Vollkommenheit und Schönheit und
                    das Mittel, das uns der Schwelle der Einheit nahebringt. Sie
                    ist Ehre und Ruhm in der Menschenwelt und die größte Gabe
                    Gottes. Erkenntnis ist dasselbe wie Führung, Unwissenheit
                    aber ist wirkliche Verirrung.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Buch der Gewissheit">
                    Wer auf dem Pfade des Glaubens wandelt, wer nach dem Weine
                    der Gewißheit schmachtet, muß sich läutern von allem, was
                    irdisch ist – sein Ohr von leerem Geschwätz, sein Gemüt von
                    eitlem Trug, sein Herz von der Liebe zur Welt, sein Auge von
                    allem Vergänglichen. Auf Gott muß er bauen, an Ihn sich
                    halten und auf Seinem Wege wandeln. Dann wird er würdig
                    sein, daß ihm in ihrer Glorie die Sonne göttlicher
                    Erkenntnis und Einsicht strahle und er zum Gefäße werde für
                    nie geschaute, unendliche Gnaden. Denn nie darf ein Mensch
                    hoffen, zur Erkenntnis des Allherrlichen zu gelangen, nie
                    kann er vom Strome göttlicher Erkenntnis und Weisheit
                    trinken, nie kann er in den Wohnsitz der Unsterblichkeit
                    eingehen noch teilhaben am Kelche göttlicher Nähe und Gunst
                    – es sei denn, er lasse davon ab, die Worte und Werke
                    sterblicher Menschen zum Maßstab wahren Erfassens und
                    Erkennens Gottes und Seiner Propheten zu nehmen.
                </NormalQuote>

                <NormalQuote reference="Báb, Eine Auswahl aus seinen Schriften">
                    Wähne nicht, die allgenügende Kraft Gottes sei leerer Trug.
                    Sie ist der wahre Glaube, den du für die Manifestation
                    Gottes in jeder Sendung hegst. Sie ist ein Glaube, der mehr
                    genügt als alles, was auf Erden ist, während nichts, was auf
                    Erden erschaffen wurde, außer dem Glauben dir genügen kann.
                    Bist du kein Gläubiger, so verdammt dich der Baum göttlicher
                    Wahrheit zum Verlöschen. Bist du ein Gläubiger, so wird dir
                    dein Glaube über alle Dinge auf Erden hinaus genügen, selbst
                    wenn du nichts besitzest.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das Geheimnis göttlicher Kultur">
                    Eine eingehende Untersuchung wird belegen, daß die
                    Hauptursache der Unterdrückung und des Unrechts, der
                    Ehrlosigkeit, Regelwidrigkeit und Unordnung die Tatsache
                    ist, daß es dem Volk an religiöser Überzeugung und an
                    Erziehung mangelt. Wenn das Volk echt religiös, gebildet und
                    geschult ist und es sieht sich zum Beispiel einer
                    Schwierigkeit gegenüber, kann es sich an die Ortsbehörden
                    wenden; trifft es dort nicht auf Gerechtigkeit und kann es
                    nicht seine angemessenen Ansprüche durchsetzen, stellt es
                    vielmehr fest, daß die örtliche Verwaltung im Widerspruch zu
                    Gottes Wohlgefallen und zur Rechtlichkeit des Königs steht,
                    dann kann das Volk seinen Fall der nächsten Gerichtsinstanz
                    vortragen und die Abweichung der Ortsbehörden von den
                    Gesetzen des Geistes darstellen. Das Gericht kann sich die
                    Akten der Ortsbehörden über den Fall kommen lassen und
                    Gerechtigkeit schaffen. Zur Zeit fehlen jedoch dem größten
                    Teil der Bevölkerung aus Mangel an Schulbildung sogar die
                    Worte, um ein Anliegen auszudrücken.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das Geheimnis göttlicher Kultur">
                    Die Religion ist das Licht der Welt; Fortschritt, Erfolg und
                    Glück des Menschen sind das Ergebnis seines Gehorsams
                    gegenüber den Gesetzen, die in den heiligen Büchern
                    niedergelegt sind. Kurz, es läßt sich nachweisen, daß in
                    diesem Leben – sowohl nach äußerlichen als auch nach
                    geistigen Gesichtspunkten betrachtet – die Religion das
                    mächtigste Bollwerk, der sicherste, dauerhafteste und
                    beständigste Schutz für die ganze Welt ist.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Ansprachen in Paris">
                    Religion und Wissenschaft sind die beiden Flügel, auf denen
                    sich die menschliche Geisteskraft zur Höhe erheben und mit
                    denen die menschliche Seele Fortschritte machen kann.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Ansprachen in Paris">
                    Es gibt keinen Widerspruch zwischen wahrer Religion und
                    Wissenschaft. Wenn eine Religion im Gegensatz zur
                    Wissenschaft steht, wird sie zu reinem Aberglauben; das
                    Gegenteil vom Wissen ist die Unwissenheit.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default FortschreitendeOffenbarungZitate;
